import { CanActivate, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { AbtApiService } from "./api/abt-api.service";
import { catchError, concatMap, map } from "rxjs/operators";
import { EmployeeShare } from "../generated/abt-backend/model/employeeShare";
import { Period } from "../generated/abt-backend/model/period";
import { AppConfigService } from "./app-config.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly appConfigService: AppConfigService,
    private readonly abtApiService: AbtApiService,
  ) {
  }

  canActivate(): Observable<boolean> {
    return this.abtApiService.validateUser().pipe(
      concatMap((employeeShare: EmployeeShare) => {
        if (!employeeShare) {
          this.router.navigateByUrl("/unauthorized");
        }
        return this.appConfigService.period$.pipe(
          map((period: Period) => {
            switch (period) {
              case "VALID_PERIOD":
                return true;
              case "INTERRUPTED_PERIOD":
                this.router.navigateByUrl("/unauthorized");
                break;
              case "BEFORE_PERIOD":
                this.router.navigateByUrl("/expired");
                break;
              case "AFTER_PERIOD":
                this.router.navigateByUrl("/expired");
                break;
              default:
                this.router.navigateByUrl("/unauthorized");
            }
            return false;
          }),
        );
      }),
      catchError(() => {
        this.router.navigateByUrl("/unauthorized");
        return of(false);
      }),
    );
  }
}