import { Injectable, OnDestroy } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { Config } from '../generated/abt-backend/model/config';
import { map } from "rxjs/operators";
import { AbtApiService } from "./api/abt-api.service";

@Injectable({
  providedIn: 'root'
})
export class AppConfigService implements OnDestroy {

  private readonly _config$: ReplaySubject<Config> = new ReplaySubject<Config>(1);

  public readonly config$ = this._config$.asObservable();

  public readonly period$ = this.config$.pipe(map((config: Config) => config.period));

  constructor(abtApiService: AbtApiService)  {
    abtApiService.getConfig().subscribe((config: Config) => this._config$.next(config));
  }

  ngOnDestroy(): void {
    this._config$.complete();
  }
}
