import { CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AbtApiService } from "./api/abt-api.service";
import { map } from "rxjs/operators";

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private router: Router,
    private abtApiService: AbtApiService) {
  }

  canActivate(): Observable<boolean> {
    return this.abtApiService.validateAdmin().pipe(
      map((result: boolean) => {
        if (!result) {
          this.router.navigateByUrl("/unauthorized");
        }
        return result;
      }),
    );
  }
}