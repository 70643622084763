export const DE_TRANSLATIONS = {
  SERVICE_NAME: "Aktienbeteiligungstool",
  BFC_UNAUTHORIZED: {
    TITLE: "Leider sind Sie nicht berechtigt, an der BKW Aktienbeteiligung teilzunehmen.",
    CONTENT: "Adressaten des Angebots sind der Sekretär des Verwaltungsrates der BKW AG sowie alle hauptamtlich angestellten Mitarbeitenden der BKW und Ihrer Konzerngesellschaften in den Levels 3 bis 6, welche Ende August in ungekündigtem Arbeitsverhältnis stehen und deren Probezeit (drei Monate) am 1. September des Bezugsjahres abgelaufen ist. Mitarbeitende, welche im Bezugsjahr nach dem 30. September in Pension gehen, sind zur Teilnahme berechtigt. Berücksichtigt werden vollkonsolidierte Konzerngesellschaften. Neue Konzerngesellschaften müssen für eine Berücksichtigung per Ende des ersten Quartals 2024 zur BKW Gruppe gehören.",
    BUTTON: "Bei Fragen wenden Sie sich bitte via Employee Center an HR4you.",
    BUTTONLINK: "https://bkwprod.service-now.com/esc",
  },
  EXPIRED: {
    TITLE: "Bestellfrist abgelaufen!",
    CONTENT: "Leider können wir keine Bestellungen mehr berücksichtigen. Wir müssen uns an die vorgegebenen und kommunizierten Bezugsfristen ({{from}} bis {{to}}) halten. Danke für Ihr Verständnis.",
  },
  DELETE_EMPLOYEESHARE_DIALOG: {
    BUTTON: {
      CANCEL: "Nein",
      SUBMIT: "Ja",
    },
    CONFIRM: {
      TITLE: "Möchtest du den Eintrag wirklich löschen?",
      DESCRIPTION: "Der Eintrag wird in der Datenbank unwiederruflich gelöscht!",
      DELETED: "Erfolgreich gelöscht!",
      ERROR: "Daten konnten nicht gelöscht werden. Versuchen Sie es erneut.",
    },
  },

  CREATE_EMPLOYEESHARE_DIALOG: {
    BUTTON: {
      CANCEL: "Abbrechen",
      SUBMIT: "Erstellen",
    },
    FORM: {
      TITLE: "Neuen Datensatz erfassen",
      SUCCESS: "Erfolgreich erstellt!",
      ERROR: "Datensatz konnte nicht erstellt werden. Versuchen Sie es erneut.",
    },
    CONFIRM_OVERWRITE: {
      CONFIRM: {
        TITLE: "Der bereits gespeicherte Datensatz mit der Personalnummer {{personalNr}} wird überschrieben, bist du sicher?",
        DESCRIPTION: "Diese Aktion kann nicht rückgängig gemacht werden!",
      },
      BUTTON: {
        SUBMIT: "Überschreiben",
        CANCEL: "Abbrechen",
      },
    },
  },

  FILTER_EMPLOYEESHARE_DIALOG: {
    BUTTON: {
      CANCEL: "Abbrechen",
      SUBMIT: "Filtern",
      RESET: "Filter zurücksetzen",
    },
    FORM: {
      TITLE: "Datensätze filtern",
      SUCCESS: "Filter angewendet!",
      RESET: "Filter zurückgesetzt",
      ERROR: "Filter konnten nicht angewendet werden. Versuchen Sie es erneut.",
    },
  },

  ADD_EMPLOYEESHARE_DIALOG: {
    BUTTON: {
      CANCEL: "Nein",
      SUBMIT: "Ja",
    },
    CONFIRM_OVERWRITE: {
      TITLE: "Bereits gespeicherte Datensätze könnten überschrieben werden, bist du sicher?",
      DESCRIPTION: "Diese Datensätze werden überschrieben!",
    },
    CONFIRM_DELETE: {
      TITLE: "Bist du sicher, dass alle Daten gelöscht werden sollten?",
      DESCRIPTION: "Die bisherigen gespeicherten Daten werden gelöscht!",
    },
  },

  EDIT_EMPLOYEESHARE_DIALOG: {
    BUTTON: {
      CANCEL: "Abbrechen",
      SUBMIT: "Speichern",
    },
    FORM: {
      TITLE: "Bearbeiten",
      SUCCESS: "Datensatz wurde angepasst!",
      ERROR: "Datensatz konnte nicht angepasst werden. Versuchen Sie es erneut.",
    },
  },

  ERRORS: {
    LOADING_DATA: "Die Daten konnten nicht geladen werden. Versuchen Sie es erneut.",
  },

  VALIDATION: {
    REQUIRED: "Pflichtfeld",
    MAX: "Es dürfen nicht so viele Aktien bestellt werden!",
    PATTERN: "Es darf nur eine Zahl eingegeben werden",
  },

  GLOBAL_NAV: {
    LOGOUT: "Logout",
  },

  DEFAULT: {
    TITLE: "Aktienbeteiligung",
    ORDER: {
      TITLE: "Sie können bis zu {{amountMaxShares}} Aktien bestellen.",
      SUCCESS: "Wir haben Ihre Bestellung erhalten.",
      ERROR: "Die Bestellung konnte aufgrund von ungültigen Eingaben nicht aufgenommen werden. Versuchen Sie es erneut.",
      ORDER: "Bestellen",
      PAYMENT: "Bezahlung",
      PAYMENT_INFO: ": als Einmalzahlung – Sie werden anfang Oktober eine Rechnung in Schweizer Franken vom Billing Service der BKW erhalten.",
      IMPORTANT: "Bitte beachten Sie:",
      IMPORTANT_INFO: "<li>Die bezogenen Aktien werden in das Aktienregister der BKW eingetragen und auf Ihr BKW Aktionärsdepot bei der Credit Suisse<sup>1</sup> übertragen.</li>" +
          "<li>Sofern Sie noch kein BKW Depot bei der Credit Suisse<sup>1</sup> haben, erhalten Sie von der Bank per Post zwei Briefe mit den Zugangsunterlagen für das Online Banking-Tool (1. Benutzer ID und Passwort, 2. gelbe SecureSign-Grafik). Bitte melden Sie sich bis zum {{loginDeadline}} im Online Banking der Credit Suisse<sup>1</sup> an, akzeptieren Sie die Vereinbarung und schliessen Sie die Online-Zertifizierung ab.<br>" +
          "Hinweis: Bitte teilen Sie der Credit Suisse<sup>1</sup> mit, wenn Sie die Eröffnungsdokumente nicht online abschliessen möchten. Die Credit Suisse<sup>1</sup> wird Ihnen die Vereinbarung sowie die Unterlagen zur Status-Deklaration und zum Automatischen Informationsaustausch (AIA) per E-Mail oder per Post zustellen.<br>" +
          "Wenn Sie das Online Banking nutzen, profitieren Sie mehrfach. Sie erhalten rund um die Uhr Einsicht auf Ihre Plandaten und bei online Aktienverkäufen einen Rabatt von maximal CHF 40.00.</li>" +
          "<li>Die Eintragung und Depotführung bei der Credit Suisse<sup>1</sup> ist für Sie unentgeltlich.</li>" +
          "<li>Mit der Bestellung erklären Sie sich mit den Bestimmungen zur Aktienbeteiligung einverstanden.</li>",
      TOTAL: "Total",
      CHECKBOX: "Ich habe die <a target='_blank' href='./assets/regulations/Bestimmungen_Aktienbeteiligung_de.pdf'>Bestimmungen</a> gelesen und akzeptiere diese",
      ADDRESS_CHECK: "Ich bestätige, dass meine Adresse {{street}}, {{zip}} {{place}} aktuell ist. (falls nicht, bitte kontaktieren Sie Ihr HR für die Aktualisierung)",
      FOOTNOTE_1: "Teil des UBS Konzerns"
    },
  },
  MANAGEMENT: {
    TITLE: "Management",
    UPLOAD: "Excel hochladen",
    DOWNLOAD: "Excel herunterladen",
    CREATE: "Neuer Datensatz",
    FILTER: "Filtern",
    SHOW_ALL: "Alle anzeigen",
    SHOW_DUPLICATED: "Doppelte Adressen anzeigen",
    EMPLOYEE_TITLE: {
      M: "Herr",
      W: "Frau",
    },
    EMPLOYEE_LANGUAGE: {
      DE: "Deutsch",
      FR: "Französisch",
      IT: "Italienisch",
    },
    EXPORT: {
      BILLING: "Export für Billing",
      CS: "Export für CS",
    },
  },

  IMPORT: {
    MULTI: {
      TITLE: "Upload Berechtigungsliste",
      DESCRIPTION: "Sie können die Berechtigungsliste hier hochladen. Entweder via Suche über den File Explorer, oder per Drag and Drop in die untenstehende Box.",
      UPLOAD_TITLE: "Wählen Sie ein Excel für den Upload aus",
      UPLOAD_DESCRIPTION: "oder per Drag & Drop",
      UPDATE_LOCKED: "Aktualisiere geschützte Einträge",
      TRUNCATE: "Bereits gespeicherte Daten löschen",
      ACTIONS: {
        IMPORT: "Importieren",
      },
      NOTIFICATION: {
        ERROR: "Dokument konnte nicht hochgeladen werden. Das Dokument ist entweder kein Excel-File, grösser als 10MB oder hat nicht alle benötigten Spalten.",
        SUCCESS: "{{numberOfImports}} Mitarbeitende wurden erfolgreich in die Datenbank geladen.",
        DRAG_N_DROP: {
          ERROR: "Es darf nur ein File hochgeladen werden.",
        },
      },
    },
    ERRORS: {
      COLUMN_NAMES: {
        INFO: "Bitte Spaltenbeschriftungen und -Reihenfolge gemäss der Exportdatei beibehalten und diese nicht ändern. Spalten, die anders betitelt sind als in der Exportdatei, können nicht eingelesen und somit die Daten nicht aktualisiert werden.",
        FOLLOWING_COLUMNS_UNKNOWN: "Folgende Felder werden ignoriert: ",
        FOLLOWING_COLUMNS_MISSING: "Folgende Felder fehlen: ",
        TITLE: "Der Upload könnte Fehler enthalten",
        CANCEL: "Abbrechen",
        IGNORE: "Ignorieren",
      },
      VALIDATION_ERROR: "Der Eintrag '{{entity}}' weisst auf dem Feld '{{fieldName}}' folgende Mängel auf '{{message}}'.",
      VALIDATION_ERRORS: "Die Einträge ({{entities}}) weissen auf dem Feld '{{fieldName}}' folgende Mängel auf '{{message}}'.",
    },
  },

  EMPLOYEE_SHARE: {
    LOCKED: "Gesperrt",
    PERSONAL_NUMBER: "Personal Nummer",
    FIRST_NAME: "Vorname",
    LAST_NAME: "Nachname",
    TITLE: "Anrede",
    EMAIL: "E-Mail",
    LOAN_NUMBER: "Leihgabennummer",
    AMOUNT_ORDERED_SHARES: "Anzahl bestellte Aktien",
    AMOUNT_MAX_SHARES: "Max. Anzahl Aktien",
    STREET: "Strasse",
    ZIP: "Postleitzahl",
    PLACE: "Ort",
    COUNTRY: "Land",
    NATIONALITY: "Nationalität",
    BIRTH_DATE: "Geburtsdatum",
    ORDER_DATE: "Bestellt am",
    LANGUAGE: "Sprache",
    LEVEL: "Level",
    PERSONNEL_AREA_NUMBER: "Personalbereichnummer",
    PERSONNEL_AREA: "Personalbereich",
    ABBREVIATION: "Kürzel",
    EDIT: "Bearbeiten",
    DELETE: "Löschen",
  },
};