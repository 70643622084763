import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { EmployeeShare } from "../../generated/abt-backend/model/employeeShare";
import { concatMap, map } from "rxjs/operators";
import { of } from "rxjs";
import { ExposablePageEmployeeShare } from "../../generated/abt-backend/model/exposablePageEmployeeShare";
import { EmployeeShareArgs } from "../../model/employee-share-args.interface";
import { Config } from "../../generated/abt-backend/model/config";

@Injectable({
  providedIn: "root",
})
export class AbtApiService {

  private restApiUrl: string = this.configurationService.configuration.apiUrl;

  private restEmployeeShareApiUrl: string = this.restApiUrl + this.configurationService.configuration.employeeSharesUrl;

  private restMeApiUrl: string = this.restApiUrl + this.configurationService.configuration.meUrl;

  constructor(
    private configurationService: BfcConfigurationService,
    private httpClient: HttpClient) {
  }

  validateUser(): Observable<EmployeeShare> {
    return this.httpClient.get<EmployeeShare>(`${this.restMeApiUrl}/validate`).pipe(
      map((employeeShare: EmployeeShare) => this.fixEmployeeDate(employeeShare)),
    );
  }

  validateAdmin(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.restMeApiUrl}/validateAdmin`);
  }


  fetchEmployeeShareByPersonalNr(personalNr: number): Observable<EmployeeShare> {
    return this.httpClient.get<EmployeeShare>(`${this.restEmployeeShareApiUrl}/${personalNr}`).pipe(
      map((employeeShare: EmployeeShare) => this.fixEmployeeDate(employeeShare)),
    );
  }

  fetchEmployeeShares(filterEmployeeShare?: EmployeeShare, args: EmployeeShareArgs = {}): Observable<ExposablePageEmployeeShare> {
    let queryParams = this.buildQueryParams(args);
    for (const filterAttribute of Object.keys(filterEmployeeShare ?? {})) {
      if (!filterEmployeeShare[filterAttribute]) {
        continue;
      }

      switch (filterAttribute) {
        case "birthDate":
          queryParams = queryParams.append(filterAttribute, filterEmployeeShare[filterAttribute].toISOString());
          break;
        default:
          queryParams = queryParams.append(filterAttribute, filterEmployeeShare[filterAttribute]);
          break;
      }
    }

    const url = `${this.restEmployeeShareApiUrl}${(args.onlyDuplicatedAddress ? "/onlyDuplicatedAddress" : "")}`;

    return this.httpClient.get<ExposablePageEmployeeShare>(url, { params: queryParams }).pipe(
      map((response: ExposablePageEmployeeShare) => {
        for (let employeeShare of response.content) {
          this.fixEmployeeDate(employeeShare)
        }
        return response;
      }),
    );
  }

  addEmployeeShares(request: Partial<EmployeeShare>[], truncate: boolean = false, ignoreLocked: boolean = false): Observable<EmployeeShare[]> {
    return this.httpClient.post<EmployeeShare[]>(`${this.restEmployeeShareApiUrl}/full?flushAll=${truncate}&ignoreLocked=${ignoreLocked}`, request).pipe(
      map((response: EmployeeShare[]) => {
        for (let employeeShare of response) {
          this.fixEmployeeDate(employeeShare);
        }
        return response;
      }),
    );
  }

  deleteEmployeeShare(personalNr: number): Observable<boolean> {
    return this.httpClient.delete<void>(`${this.restEmployeeShareApiUrl}/${personalNr}`).pipe(concatMap(() => of(true)));
  }

  isDoublePersonalNr(personalNr: number): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.restEmployeeShareApiUrl}/isDoublePersonalNr/${personalNr}`);
  }

  editEmployeeShare(updatedEmployeeShare: Partial<EmployeeShare>): Observable<EmployeeShare> {
    return this.httpClient.put<EmployeeShare>(`${this.restEmployeeShareApiUrl}/${updatedEmployeeShare?.personalNr}`, updatedEmployeeShare).pipe(
      map((employeeShare: EmployeeShare) => this.fixEmployeeDate(employeeShare)),
    );
  }

  orderShares(employeeShare: EmployeeShare): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.restMeApiUrl}/order`, employeeShare);
  }

  getConfig(): Observable<Config> {
    return this.httpClient.get<Config>(`${this.restApiUrl}/property`);
  }

  private fixEmployeeDate(employee: EmployeeShare): EmployeeShare {
    if (employee?.birthDate) {
      employee.birthDate = new Date(employee.birthDate);
    }

    return employee;
  }

  private buildQueryParams(args: EmployeeShareArgs = {}): HttpParams {
    let queryParams = new HttpParams();
    if (args.page) {
      if (args.page.pageSize) {
        queryParams = queryParams.append("size", args.page.pageSize.toString());
      }
      if (args.page.pageIndex) {
        queryParams = queryParams.append("page", args.page.pageIndex.toString());
      }
    }

    if (args.sort?.active) {
      const sortParameter: string = args.sort.active + (args.sort.direction ? "," + args.sort.direction : "");
      queryParams = queryParams.append("sort", sortParameter);
    }

    return queryParams;
  }
}