import { Inject, Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { BfcTranslationService } from "@bfl/components/translation";
import { MsalService } from "@azure/msal-angular";
import { AbtApiService } from "../api/abt-api.service";
import { Router } from "@angular/router";

@Injectable()
export class InitializerProvider {
  constructor(
    @Inject(BfcTranslationService) private bfcTranslateService: BfcTranslationService,
    @Inject(BfcGoogleAnalyticsService) private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService,
    @Inject(BfcConfigurationService) private bfcConfigurationService: BfcConfigurationService,
    @Inject(MsalService) private msalService: MsalService,
    @Inject(AbtApiService) private abtApiService: AbtApiService,
    @Inject(Router) private router: Router,
  ) {
  }

  initialize(): void {
    this.bfcGoogleAnalyticsService.initGoogleTagManager(
      this.bfcConfigurationService.configuration.googleTagManagerContainerId,
    );

    const globalNavElements = document.getElementsByTagName(
      "bfe-global-navigation",
    );
    if (globalNavElements.length === 1) {
      let elements = [
        {
          key: "jobs",
          hide: true,
        },
        {
          key: "contact",
          hide: true,
        },
        {
          key: "login",
          hide: true,
        },
        {
          key: "logout",
          titleTranslatable: this.bfcTranslateService.translate("GLOBAL_NAV.LOGOUT"),
          eventId: "logoutEvent",
          icon: "bfc-icon-arrow-right",
        },
      ];
      this.abtApiService.validateAdmin().subscribe((isAdmin: boolean) => {
        if (isAdmin) {
          elements.push({
            key: "management",
            titleTranslatable: this.bfcTranslateService.translate("MANAGEMENT.TITLE"),
            eventId: "managementEvent",
            icon: "",
          });
        }
        const serviceItemsModify = JSON.stringify(elements);
        globalNavElements[0].setAttribute("service-items-modify", serviceItemsModify);

        globalNavElements[0].addEventListener("globalNavigationEvent", (event: any) => {
          if (event.detail.eventId === "logoutEvent") {
            this.msalService.logout();
          } else if (event.detail.eventId === "managementEvent") {
            this.router.navigate(["/management"]);
          }
        });
      });
    } else {
      throw new Error("too many or no global navigation element found");
    }
  }
}
