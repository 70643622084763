export const FR_TRANSLATIONS = {
  SERVICE_NAME: "Outil de participation par actions",
  BFC_UNAUTHORIZED: {
    TITLE: "Malheureusement, vous n’êtes pas autorisé à la participation au capital-actions de BKW.",
    CONTENT: "Les destinataires de l’offre sont le secrétaire du conseil d’administration de BKW SA ainsi que tous les collaborateurs des niveaux 3 à 6 exerçant une activité à titre principal chez BKW ou une société du groupe BKW, les intéressés devant se trouver dans un rapport de travail non résilié à la fin août et leur période d’essai (trois mois) devant être terminée au 1er septembre de l’année de référence. Les collaborateurs qui prennent leur retraite après le 30 septembre de l’année au cours de laquelle ils exercent leur droit sont autorisés à participer. Les sociétés du groupe consolidées par intégration globale sont prises en compte. Pour être prises en compte, les nouvelles sociétés doivent appartenir au groupe BKW à la fin du premier trimestre 2024.",
    BUTTON: "En cas de questions, veuillez-vous adresser à HR4you via le Employee Center.",
    BUTTONLINK: "https://bkwprod.service-now.com/esc",
  },
  EXPIRED: {
    TITLE: "Délai de commande expiré!",
    CONTENT: "Malheureusement, nous ne pouvons pas tenir compte de votre commande. Nous devons respecter le délai de souscription fixé et communiqué (du {{from}} au {{to}}). Nous vous remercions de votre compréhension.",
  },
  DELETE_EMPLOYEESHARE_DIALOG: {
    BUTTON: {
      CANCEL: "Non",
      SUBMIT: "Oui",
    },
    CONFIRM: {
      TITLE: "Veux-tu vraiment supprimer cette entrée?",
      DESCRIPTION: "L'entrée sera irrémédiablement supprimée de la base de données!",
      DELETED: "Supprimé avec succès!",
      ERROR: "Les données n'ont pas pu être supprimées. Réessayez.",
    },
  },

  CREATE_EMPLOYEESHARE_DIALOG: {
    BUTTON: {
      CANCEL: "Annuler",
      SUBMIT: "Créer",
    },
    FORM: {
      TITLE: "Saisir un nouvel enregistrement",
      SUCCESS: "Créé avec succès!",
      ERROR: "L'enregistrement n'a pas pu être créé. Essayez à nouveau.",
    },
    CONFIRM_OVERWRITE: {
      CONFIRM: {
        TITLE: "L'enregistrement déjà sauvegardé avec le matricule {{personnelNr}} sera écrasé, tu es sûr?",
        DESCRIPTION: "Cette action ne peut pas être annulée!",
      },
      BUTTON: {
        SUBMIT: "Écraser",
        CANCEL: "Annuler",
      },
    },
  },

  FILTER_EMPLOYEESHARE_DIALOG: {
    BUTTON: {
      CANCEL: "Annuler",
      SUBMIT: "Filtrer",
      RESET: "Réinitialiser le filtre",
    },
    FORM: {
      TITLE: "Filtrer les enregistrements",
      SUCCESS: "Filtre appliqué!",
      RESET: "Filtre réinitialisé",
      ERROR: "Les filtres n'ont pas pu être appliqués. Essayez à nouveau.",
    },
  },

  ADD_EMPLOYEESHARE_DIALOG: {
    BUTTON: {
      CANCEL: "Non",
      SUBMIT: "Oui",
    },
    CONFIRM_OVERWRITE: {
      TITLE: "Les enregistrements déjà sauvegardés pourraient être écrasés, tu es sûr?",
      DESCRIPTION: "Ces enregistrements seront écrasés!",
    },
    CONFIRM_DELETE: {
      TITLE: "Es-tu sûr que toutes les données doivent être supprimées?",
      DESCRIPTION: "Les données enregistrées jusqu'à présent seront supprimées!",
    },
  },

  EDIT_EMPLOYEESHARE_DIALOG: {
    BUTTON: {
      CANCEL: "Annuler",
      SUBMIT: "Enregister",
    },
    FORM: {
      TITLE: "Modifier",
      SUCCESS: "L'ensemble des données a été adapté!",
      ERROR: "L'enregistrement n'a pas pu être adapté. Essayez à nouveau.",
    },
  },

  ERRORS: {
    LOADING_DATA: "Les données n'ont pas pu être chargées. Essayez à nouveau.",
  },

  VALIDATION: {
    REQUIRED: "Champ obligatoire",
    MAX: "Il ne faut pas commander autant d'actions!",
    PATTERN: "Un seul chiffre peut être saisi",
  },

  GLOBAL_NAV: {
    LOGOUT: "Logout",
  },

  DEFAULT: {
    TITLE: "Participation du personnel au capital-actions",
    ORDER: {
      TITLE: "Vous pouvez commander jusqu'à {{amountMaxShares}} d'actions.",
      SUCCESS: "Nous avons bien reçu votre commande.",
      ERROR: "La commande n'a pas pu être enregistrée en raison d'une saisie non valide. Veuillez réessayer.",
      ORDER: "Commander",
      PAYMENT: "Paiement",
      PAYMENT_INFO: ": paiement unique – début octobre le Billing Service de BKW vous adressera une facture en francs suisses.",
      IMPORTANT: "Attention:",
      IMPORTANT_INFO: "<li>Les actions achetées sont inscrites au registre des actions de BKW et transférées sur votre dépôt d’actionnaire BKW auprès de Credit Suisse<sup>1</sup></li>" +
          "<li>Si vous n’avez pas encore de dépôt BKW chez Credit Suisse<sup>1</sup>, la banque vous enverra deux lettres avec les documents d’accès pour l’Online Banking (1. ID utilisateur et mot de passe, 2. Graphique SecureSign jaune). Connectez-vous avant le {{loginDeadline}} à l’Online Banking de Credit Suisse<sup>1</sup> et souscrivez à la certification en ligne.<br>" +
          "Remarque: Signalez à Credit Suisse<sup>1</sup> si vous ne souhaitez pas souscrire à la certification en ligne. Credit Suisse<sup>1</sup> vous fera alors parvenir les documents de déclaration de statut et d’échange automatique de renseignements relatifs aux comptes financiers (EAR) par e-mail ou par courrier.<br>" +
          "Si vous utilisez l’Online Banking, vous en profitez à plusieurs niveaux. Vous pourrez en effet consulter vos valeurs prévues 24h/24h et bénéficier d’un rabais de 40,00 CHF au maximum lors de vos ventes d’actions en ligne.</li>" +
          "<li>L’inscription au registre et la gestion du dépôt chez Credit Suisse<sup>1</sup> vous sont offertes à titre gracieux.</li>" +
          "<li>Par votre commande, vous déclarez accepter les dispositions relatives à la participation du personnel au capital-actions</li>",
      TOTAL: "Total",
      CHECKBOX: "J'ai lu et j'accepte les <a target='_blank' href='./assets/regulations/Bestimmungen_Aktienbeteiligung_fr.pdf'>dispositions</a>",
      ADDRESS_CHECK: "Je confirme que mon adresse {{street}}, {{zip}} {{place}} est à jour. (si ce n’est pas le cas, merci de contacter votre RH pour la mise à jour)",
      FOOTNOTE_1: "Fait partie du groupe UBS"
    },
  },
  MANAGEMENT: {
    TITLE: "Management",
    UPLOAD: "Upload Excel",
    DOWNLOAD: "Download Excel",
    CREATE: "Nouvel enregistrement de données",
    FILTER: "Filtrer",
    SHOW_ALL: "Tout afficher",
    SHOW_DUPLICATED: "Afficher des adresses en double",
    EMPLOYEE_TITLE: {
      M: "M.",
      W: "Mme",
    },
    EMPLOYEE_LANGUAGE: {
      DE: "Allemand",
      FR: "Français",
      IT: "Italien",
    },
    EXPORT: {
      BILLING: "Exportation pour billing",
      CS: "Exportation pour CS",
    },
  },

  IMPORT: {
    MULTI: {
      TITLE: "Upload de la liste d'autorisation",
      DESCRIPTION: "Vous pouvez télécharger la liste des autorisations ici. Soit par recherche via l'explorateur de fichiers, soit par glisser-déposer dans la boîte ci-dessous.",
      UPLOAD_TITLE: "Sélectionnez un Excel pour le téléchargement",
      UPLOAD_DESCRIPTION: "ou par glisser-déposer",
      UPDATE_LOCKED: "Mettre à jour les entrées protégées",
      TRUNCATE: "Supprimer les données déjà enregistrées",
      ACTIONS: {
        IMPORT: "Importer",
      },
      NOTIFICATION: {
        ERROR: "Le document n'a pas pu être téléchargé. Soit le document n'est pas un fichier Excel, soit il est plus grand que 10MB, soit il n'a pas toutes les colonnes requises.",
        SUCCESS: "{{numberOfImports}} collaborateurs ont été chargés avec succès dans la base de données.",
        DRAG_N_DROP: {
          ERROR: "Un seul fichier peut être téléchargé.",
        },
      },
    },
    ERRORS: {
      COLUMN_NAMES: {
        INFO: "Merci de conserver les intitulés et l'ordre des colonnes conformément au fichier d'exportation et de ne pas les modifier. Les colonnes dont le titre est différent de celui du fichier d'exportation ne peuvent pas être importées et les données ne peuvent donc pas être actualisées.",
        FOLLOWING_COLUMNS_UNKNOWN: "Les champs suivants sont ignorés : ",
        FOLLOWING_COLUMNS_MISSING: "Les champs suivants sont manquants : ",
        TITLE: "Le téléchargement peut contenir des erreurs",
        CANCEL: "Annuler",
        IGNORE: "Ignorer",
      },
      VALIDATION_ERROR: "L'entrée '{{entity}}' présente les défauts suivants '{message}}' sur le champ '{{fieldName}}' présente les défauts suivants.",
      VALIDATION_ERRORS: "Les entrées ({{entities}}) présentent les défauts suivants '{message}}' sur le champ '{{fieldName}}' présente les défauts suivants.",
    },
  },

  EMPLOYEE_SHARE: {
    LOCKED: "Verrouillé",
    PERSONAL_NUMBER: "Numéro personnel",
    FIRST_NAME: "Prénom",
    LAST_NAME: "Nom de famille",
    TITLE: "Citation",
    EMAIL: "E-mail",
    LOAN_NUMBER: "Numéro de prêt",
    AMOUNT_ORDERED_SHARES: "Nombre d'actions commandées",
    AMOUNT_MAX_SHARES: "Max. mombre d'actions",
    STREET: "Rue",
    ZIP: "Code postal",
    PLACE: "Localité",
    COUNTRY: "Pays",
    NATIONALITY: "Nationalité",
    BIRTH_DATE: "Date de naissance",
    ORDER_DATE: "Commandé le",
    LANGUAGE: "Langue",
    LEVEL: "Niveau",
    PERSONNEL_AREA_NUMBER: "Numéro de domaine personnel",
    PERSONNEL_AREA: "Domaine du personnel",
    ABBREVIATION: "Abréviation",
    EDIT: "Modifier",
    DELETE: "Supprimer",
  },
};