import { IABTConfiguration } from "../configuration";
import { BrowserCacheLocation, InteractionType } from "@azure/msal-browser";

export const IS_IE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const GENERAL_ENV: IABTConfiguration = {
  employeeSharesUrl: "/employeeshares",
  meUrl: "/me",
  exportUrl: "/export",
  googleTagManagerContainerId: "GTM-N4VBJZH",
  msalConfig: {
    auth: {
      clientId: "4e5ad59a-ffaf-4a37-b6b4-d9335070af53", // jad-abt
      authority: "https://login.microsoftonline.com/bkwfmbenergie.onmicrosoft.com",
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: IS_IE, // set to true for IE 11
    },
  },
  msalAngularInterceptorConfig: {
    interactionType: InteractionType.Redirect,
    // array declaration as any is a workaround, because new Map() was recognized only as typeof object from msal
    protectedResourceMap: [["/*", ["api://d50eda56-acc7-4956-a64d-5bbcc061bccc/Read", "user.read"]]] as any, // jad-abt-api
  },
  msalAngularGuardConfig: {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        "user.read",
        "api://d50eda56-acc7-4956-a64d-5bbcc061bccc/Read", // jad-abt-api
      ],
    },
    loginFailedRoute: "./unauthorized",
  },
  uploadConfigs: {
    allowedExtensions: ".xlsx",
    maxFileSize: 10_000_000, // 10MB
  },
  downloadConfigs: {
    fileName: "Aktienbeteiligung",
    sheetName: "Berechtigungen",
    fileExtension: ".xlsx",
    dateFormat: "m/d/yy",
    timeZone: "Europe/Zurich",
  },
  dateFormatManagement: "dd.MM.yyyy",
  currency: "CHF",
  shareOptions: [75, 150],
  levelOptions: [
    "Konzernleitung",
    "Level 1",
    "Level 2",
    "Level 3",
    "Level 4",
    "Level 5",
    "Level 6",
    "Ohne Level",
    "Pensionäre",
    "Verwaltungsrat"
  ],
};
