export const IT_TRANSLATIONS = {
  SERVICE_NAME: "Condividi lo strumento di partecipazione",
  BFC_UNAUTHORIZED: {
    TITLE: "Purtroppo non dispone dell’autorizzazione necessaria per una partecipazione azionaria a BKW.",
    CONTENT: "L’offerta è riservata al segretario del consiglio di amministrazione di BKW SA e a tutti i collaboratori di livello da 3 a 6 impiegati a titolo principale presso BKW e le società del Gruppo, in possesso di un contratto di lavoro in essere al 31 agosto e il cui periodo di prova (di tre mesi) è terminato il 1° settembre dell’anno di riferimento. Il diritto alla partecipazione azionaria è esteso ai collaboratori entrati in pensione dopo il 30 settembre dell’anno di riferimento. Sono prese in considerazione le società del Gruppo consolidate integralmente. Per essere considerate, le nuove società del Gruppo devono risultare appartenenti al Gruppo BKW al termine del primo trimestre 2024.",
    BUTTON: "In caso di domande può rivolgersi a HR4you tramite il Employee Center.",
    BUTTONLINK: "https://bkwprod.service-now.com/esc",
  },
  EXPIRED: {
    TITLE: "Il termine per l'ordine è scaduto!",
    CONTENT: "Purtroppo non possiamo prenderlo in considerazione. Dobbiamo attenerci alle date di scadenza stabilite e comunicate (dal {{from}} al {{to}}). La ringraziamo per la comprensione.",
  },
  DELETE_EMPLOYEESHARE_DIALOG: {
    BUTTON: {
      CANCEL: "No",
      SUBMIT: "Sì",
    },
    CONFIRM: {
      TITLE: "Volete davvero cancellare la voce?",
      DESCRIPTION: "La voce è irrevocabilmente cancellata dal database!",
      DELETED: "Cancellato con successo!",
      ERROR: "Non è stato possibile cancellare i dati. Riprova.",
    },
  },

  CREATE_EMPLOYEESHARE_DIALOG: {
    BUTTON: {
      CANCEL: "Annullamento",
      SUBMIT: "Creare",
    },
    FORM: {
      TITLE: "Inserire un nuovo set di dati",
      SUCCESS: "Creato con successo!",
      ERROR: "Non è stato possibile creare un record. Riprova.",
    },
    CONFIRM_OVERWRITE: {
      CONFIRM: {
        TITLE: "Il record già salvato con il numero personale {{personalNr}} verrà sovrascritto, sei sicuro?",
        DESCRIPTION: "Questa azione non può essere annullata!",
      },
      BUTTON: {
        SUBMIT: "Sovrascrivere",
        CANCEL: "Annullamento",
      },
    },
  },

  FILTER_EMPLOYEESHARE_DIALOG: {
    BUTTON: {
      CANCEL: "Annullamento",
      SUBMIT: "Filtro",
      RESET: "Azzeramento del filtro",
    },
    FORM: {
      TITLE: "Filtrare i record",
      SUCCESS: "Filtro applicato!",
      RESET: "Il filtro è stato resettato",
      ERROR: "Non è stato possibile applicare i filtri. Riprova.",
    },
  },

  ADD_EMPLOYEESHARE_DIALOG: {
    BUTTON: {
      CANCEL: "No",
      SUBMIT: "Sì",
    },
    CONFIRM_OVERWRITE: {
      TITLE: "I record già salvati potrebbero essere sovrascritti, sei sicuro?",
      DESCRIPTION: "Questi record verranno sovrascritti!",
    },
    CONFIRM_DELETE: {
      TITLE: "Siete sicuri che tutti i dati debbano essere cancellati?",
      DESCRIPTION: "I dati precedentemente memorizzati verranno cancellati!",
    },
  },

  EDIT_EMPLOYEESHARE_DIALOG: {
    BUTTON: {
      CANCEL: "Annullamento",
      SUBMIT: "Risparmiare",
    },
    FORM: {
      TITLE: "Modifica",
      SUCCESS: "Il set di dati è stato modificato!",
      ERROR: "Non è stato possibile regolare il record. Riprova.",
    },
  },

  ERRORS: {
    LOADING_DATA: "Non è stato possibile caricare i dati. Riprova.",
  },

  VALIDATION: {
    REQUIRED: "Campo obbligatorio",
    MAX: "Non possono essere ordinate tante azioni!",
    PATTERN: "È possibile inserire un solo numero",
  },

  GLOBAL_NAV: {
    LOGOUT: "Logout",
  },

  DEFAULT: {
    TITLE: "Partecipazione azionaria",
    ORDER: {
      TITLE: "È possibile ordinare fino a {{amountMaxShares}} azioni.",
      SUCCESS: "Abbiamo ricevuto il vostro ordine.",
      ERROR: "Non è stato possibile registrare l'ordine a causa di voci non valide. Riprova.",
      ORDER: "Ordine",
      PAYMENT: "Pagamento",
      PAYMENT_INFO: ": una tantum, il Services Billing di BKW Le invierà una fattura in franchi svizzeri inizio ottobre.",
      IMPORTANT: "Si ricorda che:",
      IMPORTANT_INFO: "<li>Le azioni acquistate verranno inscritte nel registro delle azioni di BKW e trasferite sul Suo deposito azionario presso Credit Suisse<sup>1</sup>.</li>" +
          "<li>Qualora non disponga ancora di un deposito azionario BKW presso Credit Suisse<sup>1</sup>, la banca Le invierà per posta due comunicazioni con le credenziali d’accesso al servizio di online banking (la prima contenente nome utente e password, la seconda il grafico giallo SecureSign). La preghiamo di effettuare la registrazione al servizio di online banking di Credit Suisse<sup>1</sup>, di accettare la convenzione e di concludere il processo di certificazione online entro il {{loginDeadline}}.<br>" +
          "Nota: qualora preferisca non completare online la documentazione, La preghiamo di comunicarlo a Credit Suisse<sup>1</sup>. L’istituto Le farà pervenire via e-mail o posta la convenzione e la documentazione relativa alla dichiarazione di status e allo scambio automatico di informazioni.<br>" +
          "L’impiego del servizio di online banking Le offre molteplici vantaggi. Potrà infatti consultare in ogni momento i Suoi dati previsionali e beneficiare di uno sconto di massimo 40,00 CHF in caso di vendita delle azioni online.</li>" +
          "<li>La registrazione e la gestione del deposito presso Credit Suisse<sup>1</sup> Le sono offerti a titolo gratuito.</li>" +
          "<li>Con la conferma dell’ordine dichiara di accettare le disposizioni relative alla partecipazione azionaria.</li>",
      TOTAL: "Totale",
      CHECKBOX: "Ho letto e accetto i termini e le <a target='_blank' href='./assets/regulations/Bestimmungen_Aktienbeteiligung_it.pdf'>disposizioni</a>",
      ADDRESS_CHECK: "Confermo che il mio indirizzo {{street}}, {{zip}} {{place}} è aggiornato. (in caso contrario, contattare le risorse umane per l'aggiornamento).",
      FOOTNOTE_1: "parte del Gruppo UBS",
    },
  },
  MANAGEMENT: {
    TITLE: "Management",
    UPLOAD: "Caricare Excel",
    DOWNLOAD: "Scarica Excel",
    CREATE: "Nuovo set di dati",
    FILTER: "Filtro",
    SHOW_ALL: "Mostra tutti",
    SHOW_DUPLICATED: "Mostra gli indirizzi duplicati",
    EMPLOYEE_TITLE: {
      M: "Sig. (m)",
      W: "Sig.a (w)",
    },
    EMPLOYEE_LANGUAGE: {
      DE: "Tedesco",
      FR: "Francese",
      IT: "Italiano",
    },
    EXPORT: {
      BILLING: "Esportazione per billing",
      CS: "Esportazione per cs",
    },
  },

  IMPORT: {
    MULTI: {
      TITLE: "Caricare l'elenco delle autorizzazioni",
      DESCRIPTION: "È possibile caricare l'elenco delle autorizzazioni qui. Cercando in Esplora file o trascinando e rilasciando nel riquadro sottostante.",
      UPLOAD_TITLE: "Selezionare un Excel per il caricamento",
      UPLOAD_DESCRIPTION: "o tramite trascinamento",
      UPDATE_LOCKED: "Aggiornare le voci protette",
      TRUNCATE: "Cancellare i dati già salvati in precedenza",
      ACTIONS: {
        IMPORT: "Importazione",
      },
      NOTIFICATION: {
        ERROR: "Non è stato possibile caricare il documento. Il documento non è un file Excel, è più grande di 10 MB o non contiene tutte le colonne richieste.",
        SUCCESS: "{{numberOfImports}} dipendenti sono stati caricati con successo nel database.",
        DRAG_N_DROP: {
          ERROR: "È possibile caricare un solo file.",
        },
      },
    },
    ERRORS: {
      COLUMN_NAMES: {
        INFO: "Mantenere l'etichettatura e la sequenza delle colonne come nel file di esportazione e non modificarle. Le colonne etichettate in modo diverso rispetto al file di esportazione non possono essere importate e quindi i dati non possono essere aggiornati.",
        FOLLOWING_COLUMNS_UNKNOWN: "I seguenti campi vengono ignorati: ",
        FOLLOWING_COLUMNS_MISSING: "I seguenti campi sono mancanti: ",
        TITLE: "Il caricamento potrebbe contenere errori",
        CANCEL: "Annulla",
        IGNORE: "Ignorare",
      },
      VALIDATION_ERROR: "La voce '{{entity}}' ha le seguenti carenze '{{message}}' nel campo '{{fieldName}}'.",
      VALIDATION_ERRORS: "Le voci ({{entities}}) hanno le seguenti carenze '{{message}}' nel campo '{{fieldName}}'.",
    },
  },

  EMPLOYEE_SHARE: {
    LOCKED: "Bloccato",
    PERSONAL_NUMBER: "Numero del personale",
    FIRST_NAME: "Nome",
    LAST_NAME: "Cognome",
    TITLE: "Saluto",
    EMAIL: "E-mail",
    LOAN_NUMBER: "Numero del prestito",
    AMOUNT_ORDERED_SHARES: "Numero di azioni ordinate",
    AMOUNT_MAX_SHARES: "Max. numero di azioni",
    STREET: "Via",
    ZIP: "Codice postale",
    PLACE: "Città",
    COUNTRY: "Paese",
    NATIONALITY: "Nazionalità",
    BIRTH_DATE: "Data di nascita",
    ORDER_DATE: "Nominato il",
    LANGUAGE: "Lingua",
    LEVEL: "Livello",
    PERSONNEL_AREA_NUMBER: "Numero dell'area personale",
    PERSONNEL_AREA: "Area personale",
    ABBREVIATION: "Abbreviazione",
    EDIT: "Modifica",
    DELETE: "Cancellare",
  },
};