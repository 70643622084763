import { IBfcConfiguration } from "@bfl/components/configuration";
import { GENERAL_ENV } from "./environments/general";
import { LOCAL_ENV } from "./environments/local";
import { T_ENV } from "./environments/t";
import { Q_ENV } from "./environments/q";
import { P_ENV } from "./environments/p";
import { Configuration } from "@azure/msal-browser";
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";

export const configuration: IBfcConfiguration[] = [
  {
    theme: "bkw",
    general: GENERAL_ENV,
    local: LOCAL_ENV,
    t: T_ENV,
    q: Q_ENV,
    p: P_ENV,
  },
];

export interface IABTConfiguration {
  apiUrl?: string;
  employeeSharesUrl?: string;
  meUrl?: string;
  exportUrl?: string;
  googleTagManagerContainerId?: string;
  userRole?: string;
  msalConfig?: Configuration;
  msalAngularInterceptorConfig?: MsalInterceptorConfiguration;
  msalAngularGuardConfig?: MsalGuardConfiguration;
  uploadConfigs?: UploadConfiguration;
  downloadConfigs?: DownloadConfiguration;
  dateFormatManagement?: string;
  currency?: string;
  shareOptions?: number[];
  levelOptions?: string[];
}

export interface UploadConfiguration {
  allowedExtensions?: string; // comma separated
  maxFileSize?: number; // in bytes, e.g.: 2000000 = 2 MB
}

export interface DownloadConfiguration {
  fileName?: string;
  sheetName?: string;
  fileExtension?: string;
  dateFormat?: string;
  timeZone?: string;
}