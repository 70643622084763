import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { translations } from "./config/translations/translations";

const routes: Routes = [
  {
    path: "overview",
    canActivate: [MsalGuard],
    loadChildren: () => import("./overview/overview.module").then((m) => m.OverviewModule),
  },
  {
    path: "upload",
    canActivate: [MsalGuard],
    loadChildren: () => import("./upload/upload.module").then((m) => m.UploadModule),
  },
  {
    path: "management",
    canActivate: [MsalGuard],
    loadChildren: () => import("./management/management.module").then((m) => m.ManagementModule),
  },
  {
    path: "unauthorized",
    loadChildren: () => import("@bfl/components/unauthorized").then(m => m.BfcUnauthorizedModule.asChild(translations)),
  },
  {
    path: "expired",
    loadChildren: () => import("./expired/expired.module").then((m) => m.ExpiredModule),
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "overview",
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
